
export const environment = {
  apiNewUrl: 'https://devapi.decorait.com',
  styleBaseUrl: 'https://customer-api.decorait.com',
  newBaseUrl: 'http://moodwiz-env.eba-wqzghzmp.us-east-2.elasticbeanstalk.com',
  production: false,
  facebookAppID: '175627623079742',
  development: true,
  postCodeApiUrl: 'http://api.postcodes.io/postcodes/',
  googleMapsKey: 'AIzaSyD_KrhmjPP4cIkFhrw6FX3OyLeP1ocObpw',
  throttleTime: 500,
  showErrorTimeout: 5000,
  defaultPersonroomTemplate: 'default',
  defaultCacheExpiryMinutes: 60 * 1,
  baseHref: true ? 'http://localhost:4200' : 'https://decoraitrade.com',
  cognito: {
    ClientId: '5hihit1s99e1g7cgt4kevpjk1t',
    AppWebDomain: 'trade.login.decorait.com',
    TokenScopesArray: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    UserPoolId: 'eu-west-1_pCaObeQZr',
    AdvancedSecurityDataCollectionFlag: true,
    responseType: 'code',
    RedirectUriSignIn: '$baseHref/post-login',
    RedirectUriSignOut: '$baseHref',
  },
  stripe: {
    publishableKey: 'pk_test_51NdxzvIZ56kf0iKLDu1A9CeNasmRnR1d9qU3P8AKjAMmVD61sDokeLDyMKzUgZhXhENgOUmops2Z5nD7RrFE3FJw00lZW2RKEt',
  },
  chatBot: {
    botName: 'HeadlineBot',
    botAlias: 'Test',
  },
  incomingMail: {
    sellerEMailTemplate: `test-seller+{shipmentID}@mail.moodmaestro.co.uk`,
  },
  zendesk: {
    url: 'moodmaestro.zendesk.com',
  },
};

